import React, { useState } from "react";
import TokenSummary from "../../../components/common/ReportBox";
import { Container } from "@mui/material";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import Grid from "@mui/material/Grid2";
import { GetAllSummaryReport } from "../../../lib/api";
import { useQuery } from "@tanstack/react-query";

const Summary = () => {
  const [date, setDate] = useState(new Date());
  const formattedDate = date.toLocaleDateString("en-CA");
  const { data } = useQuery({
    queryKey: ["GetAllSummaryReport", formattedDate],
    queryFn: () => GetAllSummaryReport(formattedDate),
  });
  // console.log(data);

  return (
    <>
      <div className="mt-4">
        <h4 className="text-3xl font-bold mb-5">Token Summary Report</h4>

        <div id="date-range-picker" className="flex items-center w-full ml-7">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none ">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
              </svg>
            </div>
            <Flatpickr
              value={date}
              onChange={(selectedDate) => setDate(selectedDate[0])}
              options={{ dateFormat: "Y-m-d" }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Select a date"
            />
          </div>
        </div>
      </div>

      <Container sx={{ my: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <TokenSummary
              header={" Total: " + (data?.data?.totalTokensIssued || 0)}
              Tittle={" Token Issued"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TokenSummary
              Served={"Tokens Served"}
              header={" Total : " + (data?.data?.totalCompletedTokens || 0)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TokenSummary
              Transferred={"Tokens Transferred"}
              header={"Total : " + (data?.data?.totalTransferredTokens || 0)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TokenSummary
              Average={"Average Wait Time"}
              header={"Total : " + (data?.data?.averageWaitTime || "N/A")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TokenSummary
              Peak={"Peak Hours Issued"}
              // header={"Token issued : " + (data?.data?.peakHoursIssued || 0)}
              // headerNew={"Token Called : " + (data?.data?.peakHoursCalled || 0)}
              endtime={data?.data?.peakHoursCalled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TokenSummary
              Peak={"Peak Hours Called"}
              // header={"Token issued : " + (data?.data?.peakHoursIssued || 0)}
              // headerNew={"Token Called : " + (data?.data?.peakHoursCalled || 0)}
              endtime={data?.data?.peakHoursCalled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TokenSummary
              Counter={" Performance"}
              // header={"Counter Name:" }
              peaktime={data?.data?.counterPerformanceData}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TokenSummary
              Unserved={"Unserved Tokens"}
              header={"OnHold : " + (data?.data?.tokensOnHold || 0)}
              headerNew={"Pending : " + (data?.data?.tokensPending || 0)}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Summary;
