import axios from "axios";
import Cookies from "js-cookie";
import { constant } from "../constant";

export const UserLogin = async (data) => {
  const LoginURL = "auth/login";
  try {
    const response = await axios.post(LoginURL, data);
    return response;
  } catch (err) {
    return err?.response?.data?.message;
  }
};

export const GetUserByToken = async () => {
  const GetUserURL = "auth/user";
  try {
    const response = await axios.get(GetUserURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    Cookies.set("isCustomCall", response.data.isCustomCall, { expires: null });
    Cookies.set("userType", response.data.user.UserType, { expires: null });
    Cookies.set("UserID", response.data.user.UserID, { expires: null });
    Cookies.set("CompanyId", response.data.user.CompanyID, { expires: null });
    Cookies.set("DepId", response.data.user.DepID, { expires: null });
    Cookies.set("userName", response.data.user.Username, { expires: null });
    Cookies.set("userPassword", response.data.user.Password, { expires: null });
    Cookies.set("resetToken", response.data.user.TokenResetAllowed, {
      expires: null,
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const GetAdminDashboard = () => {
  const CompanyId = Cookies.get("CompanyId");
  const GetDashboardURL = `dashBoard/${CompanyId}`;
  try {
    const response = axios.get(GetDashboardURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const GetAllCounter = () => {
  const GetAllCounterURL = `counter/${Cookies.get("CompanyId")}`;

  try {
    const response = axios.get(GetAllCounterURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const CreateCounterAPI = (data) => {
  const CreateCounterURL = "counter/create";
  try {
    const response = axios.post(CreateCounterURL, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const UpdateCounterAPI = async (data, counterId) => {
  if (!counterId) return;
  const UpdateCounterURL = `counter/update/${counterId}`;
  try {
    const response = await axios.put(UpdateCounterURL, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const GetAllDepartment = () => {
  const GetAllDepartmentURL = `department/${Cookies.get("CompanyId")}`;
  try {
    const response = axios.get(GetAllDepartmentURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const CreateDepartmentAPI = (data) => {
  const CreateDepartmentURL = "department/register";
  try {
    const response = axios.post(CreateDepartmentURL, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const UpdateDepartmentAPI = async (data, DepId) => {
  const UpdateDepartmentURL = `department/${DepId}`;
  try {
    const response = await axios.put(UpdateDepartmentURL, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const GetAllUser = () => {
  const GetAllUserURL = `auth/users/${Cookies.get("CompanyId")}`;
  try {
    const response = axios.get(GetAllUserURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const CreateUserAPI = (data) => {
  const CreateUserURL = "auth/create";
  try {
    const response = axios.post(CreateUserURL, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const UpdateUserAPI = async (data, UserId, mapArry) => {
  if (!UserId) return;
  const UpdateUserURL = `auth/user/update/${UserId}`;
  try {
    const response = await axios.put(UpdateUserURL, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    // console.log(response);
    if (response.data.status === "success") {
      await UserMap(mapArry,UserId);
    }
    return response;
  } catch (err) {
    return err;
  }
};

export const UploadImage = async (data) => {
  const UploadImageURL = constant.image_upload_url;
  try {
    const response = await axios.post(UploadImageURL, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const GetAllAdvertisement = () => {
  const GetAllAdvertisementURL = `advertisement/company/${Cookies.get("CompanyId")}`;
  try {
    const response = axios.get(GetAllAdvertisementURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const CreateAdvertisementAPI = (data) => {
  const CreateAdvertisementURL = "advertisement/create";
  try {
    const response = axios.post(CreateAdvertisementURL, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const UpdateAdvertisementAPI = async (data, AdvId) => {
  if (!AdvId) return;
  const UpdateAdvertisementURL = `advertisement/update/${AdvId}`;
  try {
    const response = await axios.put(UpdateAdvertisementURL, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const GetCompanySettings = async () => {
  const CompanyId = Cookies.get("CompanyId");
  const UserId = Cookies.get("UserID");
  const GetCompanySettingsURL = `company/companySettings/${CompanyId}/${UserId}`;
  try {
    const response = await axios.get(GetCompanySettingsURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const UpdateCompanySettings = async (data) => {
  const CompanyId = Cookies.get("CompanyId");
  const UpdateCompanySettingsURL = `company/update/companySetting/${CompanyId}`;
  try {
    const response = await axios.put(UpdateCompanySettingsURL, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

// export const GetAllUser = () => {
//   const GetAllUserURL = `auth/users/${Cookies.get("CompanyId")}`;
//   try {
//     const response = axios.get(GetAllUserURL, {
//       headers: {
//         Authorization: `Bearer ${Cookies.get("userToken")}`,
//       },
//     });
//     return response;
//   } catch (err) {
//     return err;
//   }
// };

export const GetAllDetailReport = (
  startDate,
  endDate,
  pageNumber,
  pageSize
) => {
  const CompanyId = Cookies.get("CompanyId");
  const GetAllDetailReportURL = `token/report/tokenDetail/${CompanyId}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  //  console.log(GetAllDetailReportURL);

  try {
    const response = axios.get(GetAllDetailReportURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

/// GetAllSummary
export const GetAllSummaryReport = (date) => {
  const CompanyId = Cookies.get("CompanyId");
  const GetAllSummaryReportURL = `token/report/tokenSummary/${CompanyId}?date=${date}`;
  // console.log();

  try {
    const response = axios.get(GetAllSummaryReportURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

// user mapping
export const UserMap = async (Data,UserId) => {
  // console.log(Data);

  if (!UserId) return;
  const UserMapURL = `auth/users/userMap/${UserId}`;
  // console.log(UserMap);

  try {
    const response = await axios.put(
      UserMapURL,
      {
        userMaps: Data,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("userToken")}`,
        },
      }
    );
    return response;
  } catch (err) {
    return err;
  }
};

// export const UpdateUserAPI = async (data, UserId) => {
//   if (!UserId) return;
//   const UpdateUserURL = `auth/user/update/${UserId}`;
//   try {
//     const response = await axios.put(UpdateUserURL, data, {
//       headers: {
//         Authorization: `Bearer ${Cookies.get("userToken")}`,
//       },
//     });
//     return response;
//   } catch (err) {
//     return err;
//   }
// };
