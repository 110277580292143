import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { RouteNavigation } from "../../../Routes";
import { useQuery } from "@tanstack/react-query";
import { GetAllDepartment } from "../../../lib/api";
import {
  ShowLoadingAnimation,
  ShowNoDataAnimation,
} from "../../../components/common/Animation";
import { FaRegEdit } from "react-icons/fa";

const DepartmentList = () => {
  const navigate = useNavigate();
  const { isLoading, isPending, isError, data } = useQuery({
    queryKey: ["GetAllDepartment"],
    queryFn: () => GetAllDepartment(),
  });
  const departmentData = data?.data?.department;
  return (
    <div className="py-5">
      <h4 className="text-3xl font-bold mb-5">Department List</h4>
      <div className="flex justify-end">
        <Link to={RouteNavigation.create_department}>
          <button className="bg-gray-800 hover:bg-gray-600 text-white px-4 py-2 rounded-md">
            Create New
          </button>
        </Link>
      </div>
      {isLoading || isPending ? (
        <div className="w-44 flex justify-center m-auto">
          <ShowLoadingAnimation />
        </div>
      ) : (
        <div className="w-full">
          <div className="mt-5 border-2 rounded-lg">
            <div className="overflow-auto">
              <TableContainer>
                <Table>
                  <TableHead className="bg-gray-800 rounded-xl">
                    <TableRow>
                      <TableCell style={{ color: "white" }}>Sl.No</TableCell>
                      <TableCell style={{ color: "white" }}>Department Name</TableCell>
                      <TableCell style={{ color: "white" }}>Department Prefix</TableCell>
                      <TableCell style={{ color: "white", textAlign: "center" }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {!isPending && !isLoading && !isError && (
                    <TableBody>
                      {departmentData?.map((dept, index) => (
                        <TableRow key={index + 1}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{dept.DepName}</TableCell>
                          <TableCell>{dept.DepPrefix}</TableCell>
                          <TableCell
                            onClick={() => {
                              navigate("/create-department", {
                                state: { GetDepartment: dept },
                              });
                            }}
                          >
                            <div className="flex justify-center">
                              <FaRegEdit
                                className="cursor-pointer"
                                size={"20px"}
                                color={dept.Status === true ? "green" : "red"}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-center">
        {isError ||
          (departmentData?.length === 0 && (
            <div className="w-44 flex justify-center">
              <ShowNoDataAnimation />
            </div>
          ))}
      </div>
    </div>
  );
};

export default DepartmentList;
