import axios from "axios";
import { constant } from "../constant";

axios.defaults.baseURL = constant.base_url;
// axios.defaults.baseURL = "http://localhost:3000/api/";

//Super Admin Panel API
export { GetAllCompany } from "./SuperAdminAPI";
export { CreateCompanyAPI } from "./SuperAdminAPI";
export { UpdateCompanyAPI } from "./SuperAdminAPI";

//Admin Panel API
export { UserLogin } from "./AdminAPI";
export { GetUserByToken } from "./AdminAPI";
export { GetAdminDashboard } from "./AdminAPI";
export { GetAllCounter } from "./AdminAPI";
export { CreateCounterAPI } from "./AdminAPI";
export { UpdateCounterAPI } from "./AdminAPI";
export { GetAllDepartment } from "./AdminAPI";
export { CreateDepartmentAPI } from "./AdminAPI";
export { UpdateDepartmentAPI } from "./AdminAPI";
export { GetAllUser } from "./AdminAPI";
export { CreateUserAPI } from "./AdminAPI";
export { UpdateUserAPI } from "./AdminAPI";
export { UploadImage } from "./AdminAPI";
export { GetAllAdvertisement } from "./AdminAPI";
export { CreateAdvertisementAPI } from "./AdminAPI";
export { UpdateAdvertisementAPI } from "./AdminAPI";
export { GetCompanySettings } from "./AdminAPI";
export { UpdateCompanySettings } from "./AdminAPI";
export {GetAllDetailReport}   from "./AdminAPI";

export {GetAllSummaryReport}  from "./AdminAPI";

// userMap
 export { UserMap } from "./AdminAPI"

//Coordinator Page API
export { GetToken } from "./CoordinatorAPI";
export { GetHoldToken } from "./CoordinatorAPI";
export { GetAllPendingToken } from "./CoordinatorAPI";
export { GetTokenCall } from "./CoordinatorAPI";
export { DeleteAllToken } from "./CoordinatorAPI";
export { CustomTokenCall } from "./CoordinatorAPI";
export { CompleteTokenStatus } from "./CoordinatorAPI";
export { GetDepartmentForCoordinator } from "./CoordinatorAPI";
export { GetRecallToken } from "./CoordinatorAPI";


// token transformStyle: 

 export { TokenTransfer } from "./CoordinatorAPI";

 