import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  CreateUserAPI,
  UpdateUserAPI,
  GetAllDepartment,
  UserMap,
} from "../../../lib/api";
import { RouteNavigation } from "../../../Routes";
import Cookies from "js-cookie";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import {
  Checkbox,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableContainer,
} from "@mui/material";

const CreateUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const UserData = location.state && location.state.userData;
  const UserId = UserData?.UserID;

  const [isLoading, setIsLoading] = useState(false);

  const [mapArry, setMapArry] = useState([]);
  const [checkboxError, setCheckboxError] = useState(false);

  const { data } = useQuery({
    queryKey: ["GetAllDepartment"],
    queryFn: () => GetAllDepartment(),
  });

  /* Validation Schema */
  const formSchema = yup.object().shape({
    username: yup.string().required("Account Name required"),
    userType: yup.string().required("User type required"),
    // depId: yup.string().required("Department is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(4, "Password too short"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password")], "Passwords do not match"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
    defaultValues: {
      username: UserData?.Username || "",
      // depId: UserData?.DepID || "",
      password: UserData?.Password || "",
      confirmPassword: UserData?.Password || "",
      userType: UserData?.UserType || "",
      tokenResetAllowed: UserData?.TokenResetAllowed === true ? true : false,
      // status: UserData?.Status === true ? true : false,
      status: UserData?.Status ?? true,
    },
  });

  const mutate = useMutation({
    mutationFn: (data) => UpdateUserAPI(data, UserId, mapArry),

    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      toast.success("Update User Successful", {
        position: "top-right",
        closeOnClick: true,
      });
      navigate(RouteNavigation.users);
    },
    onError: () => {
      toast.error("Update User Failed", {
        position: "top-right",
        closeOnClick: true,
      });
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });
  // console.log(UserData);

  // const handleUserSubmit = async (data) => {
  //   setIsLoading(true);
  //   const dataResponse = await CreateUserAPI(data);
  //   //  console.log(dataResponse);

  //   try {
  //     if (dataResponse.status === 201) {
  //       toast.success("Create User Successful", {
  //         position: "top-right",
  //         closeOnClick: true,
  //       });
  //       const userResponse = await UserMap(mapArry, dataResponse.data.userId);

  //       try {
  //         if (userResponse.status === 201) {
  //           toast.success("Department Update Successful", {
  //             position: "top-right",
  //             closeOnClick: true,
  //           });
  //         } else {
  //           toast.error("DepartmentUpdate Failed " + userResponse.error || "", {
  //             position: "top-right",
  //             closeOnClick: true,
  //           });
  //         }
  //       } finally {
  //         setIsLoading(false);
  //       }
  //       navigate(RouteNavigation.users);
  //     } else {
  //       toast.error("Create User Failed " + dataResponse.error || "", {
  //         position: "top-right",
  //         closeOnClick: true,
  //       });
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleUserSubmit = async (data) => {
    setIsLoading(true);

    try {
      const dataResponse = await CreateUserAPI(data);
      // console.log(dataResponse);

      if (!dataResponse) {
        throw new Error("No response received from server");
      }

      if (dataResponse.status === 409) {
        toast.error("User already exists", {
          position: "top-right",
          closeOnClick: true,
        });
        return;
      }

      if (dataResponse.status !== 201) {
        throw new Error(dataResponse.error || "Failed to create user");
      }

      try {
        const userResponse = await UserMap(mapArry, dataResponse.data.userId);

        if (userResponse.status !== 201) {
          throw new Error("User mapping failed");
        }

        toast.success("User created successfully", {
          position: "top-right",
          closeOnClick: true,
        });

        navigate(RouteNavigation.users);
      } catch (mappingError) {
        console.error("User mapping error:", mappingError);
        toast.error(`Error mapping user: ${mappingError.message}`, {
          position: "top-right",
          closeOnClick: true,
        });
      }
    } catch (error) {
      console.error("User creation error:", error);

      if (error.response) {
        switch (error.response.status) {
          case 400:
            toast.error("Invalid user data provided", {
              position: "top-right",
              closeOnClick: true,
            });
            break;
          case 401:
            toast.error("Unauthorized - Please login again", {
              position: "top-right",
              closeOnClick: true,
            });
            break;
          case 409:
            toast.error("User already exists", {
              position: "top-right",
              closeOnClick: true,
            });
            break;
            default: {
              const errorMessage = error.response.data?.error || error.response.data?.message || error.message;
              toast.error(`Creation failed: ${errorMessage}`, {
                position: "top-right",
                closeOnClick: true,
              });
            }
            
        }
      } else if (error.request) {
        toast.error("Network error - Please check your connection", {
          position: "top-right",
          closeOnClick: true,
        });
      } else {
        toast.error(`Error creating user: ${error.message}`, {
          position: "top-right",
          closeOnClick: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusSubmit = (depID, Status) => {
    const temp = [...mapArry];
    const findIndex = temp.findIndex((item) => item.depID === String(depID));

    if (findIndex !== -1) {
      temp[findIndex].Status = Status;
    } else {
      temp.push({ depID: String(depID), Status: String(Status) });
    }

    setMapArry(temp);
  };
  const validateCheckboxes = () => {
    const isAlreadyChecked = UserData?.Departments.some((item) => item.Status);
    const isAnyChecked = mapArry.some((item) => item.Status === "true");

    const isValid = isAlreadyChecked || isAnyChecked;
    setCheckboxError(!isValid);
    return isValid;
  };

  const onSubmit = async (data) => {
    const isCheckboxValid = validateCheckboxes();
    if (!isCheckboxValid) {
      return;
    }
    const CompanyId = Cookies.get("CompanyId");
    if (UserData) {
      const updatedData = {
        companyId: CompanyId,
        depId: data.depId,
        username: data.username,
        password: data.password,
        tokenResetAllowed: data.tokenResetAllowed,
        userType: data.userType,
        status: data.status,
      };

      mutate.mutate(updatedData);
    } else {
      const updatedData = {
        companyId: CompanyId,
        depId: data.depId,
        username: data.username,
        password: data.password,
        tokenResetAllowed: data.tokenResetAllowed,
        userType: data.userType,
        status: data.status,
      };
      handleUserSubmit(updatedData);
    }
  };

  return (
    <div className="py-5">
      <h4 className="text-3xl font-bold mb-5">
        {UserData ? "Update" : "Create"} User
      </h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 w-full">
            <div>
              <label>User Name</label>
              <input
                {...register("username")}
                type="text"
                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 focus:outline-none focus:ring-2"
              />
              <p className="text-red-500 ml-5">{errors.username?.message}</p>
            </div>

            <div className="grid w-full">
              <label>Type</label>
              <select
                {...register("userType")}
                className="border-[1px] p-2 rounded-lg focus:outline-none focus:ring-2"
              >
                <option>Administrator</option>
                <option>TokenGenerator</option>
                <option>TokenCoordinator</option>
                <option>TokenDisplay</option>
              </select>
              <p className="text-red-500 ml-5">{errors.userType?.message}</p>
            </div>

            <div>
              <label>Password</label>
              <input
                type="password"
                {...register("password")}
                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 focus:outline-none focus:ring-2"
                placeholder=""
              />
              <p className="text-red-500 ml-5">{errors.password?.message}</p>
            </div>

            <div>
              <label>Confirm Password</label>
              <input
                type="password"
                {...register("confirmPassword")}
                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 focus:outline-none focus:ring-2"
                placeholder=""
              />
              <p className="text-red-500 ml-5">
                {errors.confirmPassword?.message}
              </p>
            </div>
            <div className="flex gap-4 mt-2 ml-3 items-center">
              <input {...register("tokenResetAllowed")} type="checkbox" />
              <span>Allow to reset</span>
            </div>
            <div className="flex gap-4 mt-2 ml-3 items-center">
              <input {...register("status")} type="checkbox" />
              <span>Active</span>
            </div>
            <div>
              <p className="text-3xl font-bold mb-5">Department</p>
              <TableContainer>
                <Table size="small">
                  <TableHead className="bg-gray-800 -xl">
                    <TableRow>
                      <TableCell
                        style={{
                          color: "white",
                          padding: "4px 8px",
                          width: "10%",
                        }}
                      >
                        Sl.No
                      </TableCell>
                      <TableCell
                        style={{
                          color: "white",
                          padding: "2px 4px",
                          width: "10%",
                        }}
                      >
                        Department Name
                      </TableCell>
                      <TableCell
                        style={{
                          color: "white",
                          padding: "2px 4px",
                          width: "5%",
                        }}
                      >
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.data?.department?.map((dept, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ padding: "4px 8px" }}>
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{ padding: "4px 8px" }}
                          className="h-px "
                        >
                          {dept.DepName}
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            defaultChecked={UserData?.Departments.some(
                              (item, index) =>
                                item.DepID === dept.DepID && item.Status
                            )}
                            color="success"
                            onChange={(e) =>
                              handleStatusSubmit(
                                dept.DepID,
                                e.target.checked ? "true" : "false"
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {checkboxError && (
                <p className="text-red-500">
                  At least one department must be selected.
                </p>
              )}
            </div>
          </div>
          <div className="flex gap-5 justify-end">
            <Link to={RouteNavigation.users}>
              <button className="flex items-center justify-center h-8 px-6 bg-gray-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-gray-700">
                Cancel
              </button>
            </Link>
            <button
              disabled={isLoading}
              className="flex items-center justify-center h-8 px-6 bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
            >
              {isLoading ? "Loading..." : `${UserData ? "Update" : "Create"}`}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default CreateUser;
