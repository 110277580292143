import { useNavigate } from "react-router-dom";
import { GetDepartmentForCoordinator } from "../../../lib/api";
import { useQuery } from "@tanstack/react-query";
import { ShowLoadingAnimation } from "../../../components/common/Animation";
import Cookies from "js-cookie";

const AvailableCounter = () => {
  const navigate = useNavigate();
  // const userId = Cookies.get("userId");
  const { isLoading, isPending, data } = useQuery({
    queryKey: ["GetDepartmentForCoordinator"],
    queryFn: () => GetDepartmentForCoordinator(),
  });
  // console.log(data);

  const filteredData = data?.data?.counters?.filter(
    (item) => item.Status === true
  );

  if (filteredData?.length === 1) {
    if (Cookies.get("isCustomCall") === "true") {
      navigate(`/custom-counter/${filteredData[0]?.CounterID}`, {
        state: { userCounterData: filteredData[0] },
      });
    } else {
      navigate(`/counter/${filteredData[0]?.CounterID}`, {
        state: { userCounterData: filteredData[0] },
      });
    }
  }

  const handleNavigation = (counter, DepID, DepPrefix) => {
    Cookies.set("DepID", DepID);
    // console.log("Saved cookie:", DepID);
    Cookies.set("DepPrefix", DepPrefix);
    // console.log("Saved cookie:",DepPrefix);
    
    
    if (Cookies.get("isCustomCall") === "true") {
      navigate(`/custom-counter/${counter.CounterID}`, {
        state: { userCounterData: counter, DepID: DepID },
      });
    } else {
      navigate(`/counter/${counter.CounterID}`, {
        state: { userCounterData: counter, DepID: DepID },
      });
    }
  };

  const isExpired = (expireDate) => {
    const currentDate = new Date();
    const depExpireDate = new Date(expireDate);
    return depExpireDate <= currentDate;
  };

  return (
    <div className="py-5 px-4 lg:px-0 max-w-full mx-auto">
      <h4 className="text-2xl lg:text-3xl font-bold mb-5 text-center lg:text-left  ">
      Select Your Operating Section
      </h4>
      {isLoading && isPending && (
        <div className="w-44 m-auto">
          <ShowLoadingAnimation />
        </div>
      )}
      {data?.data?.department?.department?.map((dept) => {
        const expired = isExpired(dept.DepExpire);

        // {data?.data?.department?.department
        //   ?.filter((dept) => dept?.assignedUsers?.includes(userId))
        //   .map((dept) => {
        //     const expired = isExpired(dept.DepExpire);
        return (
          <div key={dept.DepID} className="border-0 rounded-4 mb-3">
            <p className="text-xl  lg:text-2xl font-bold border-b-3 border-b-indigo-300 text-black w-fit text-center mx-auto lg:mx-0">
              {dept.DepName}  ({dept.DepPrefix})
              <span
                className={`text-sm ${
                  expired ? "text-red-500" : "text-green-500"
                }`}
              >
                {expired ? "(Expired)" : ""}
              </span>
            </p>


            <div className="grid grid-cols-3 gap-5 mb-5">
              {" "}
              {/* Updated this line */}
              {dept?.Counters?.map((Counter, index) => {
                const isDisabled = expired;

                return (
                  <div
                    key={index + 1}
                    onClick={() =>
                      !isDisabled && handleNavigation(Counter, dept.DepID, dept.DepPrefix)
                    }
                    className={`w-full bg-cover bg-center bg-no-repeat mx-auto mt-2 ml-2 rounded-xl border-4 ${
                      isDisabled
                        ? "border-gray-300 cursor-not-allowed opacity-50"
                        : "border-[#3c73f6] cursor-pointer hover:shadow-[5px_5px_0_0_rgba(0,0,0,1)] hover:shadow-[#3c73f6]"
                    } p-5 sm:p-6 md:p-8 lg:p-10 text-center transition-all duration-300`}
                  >
                    <p className="text-sm sm:text-base md:text-lg lg:text-xl font-bold border-b-4 border-b-indigo-300 text-black">
                      {Counter.CounterName}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AvailableCounter;
