import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { RouteNavigation } from "../../../Routes";
import { IoPieChart, IoSettings } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { FcAdvertising } from "react-icons/fc";
import { MdDashboard } from "react-icons/md";
import { FaRegBuilding } from "react-icons/fa";
import { useNavbarContext } from "../../../lib/context/NavBar/navbarContext";
import { AiOutlineClose } from "react-icons/ai";
import { TbReport } from "react-icons/tb";
import { IoMdArrowDropdown } from "react-icons/io";

import Cookies from "js-cookie";

function Navbar() {
  const { isNavbarOpen, toggleNavbar } = useNavbarContext();
  const handleNavbarClick = () => {
    if (window.innerWidth <= 768) {
      toggleNavbar();
    }
  };
  let Admin;
  if (Cookies.get("userType") === "PlaformAdmin") {
    Admin = true;
  } else {
    Admin = false;
  }

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <aside
      id="default-sidebar"
      className={`${
        isNavbarOpen ? "translate-x-0" : "-translate-x-full"
      } fixed top-0 left-0 z-40 w-64 h-screen transition-transform bg-gray-800 text-white duration-300`}
      aria-label="Sidebar"
    >
      <div className="flex gap-5 justify-center items-center mt-5">
        <h4 className="text-center text-2xl font-semibold">Token Manager</h4>
        <div>
          <AiOutlineClose
            className="cursor-pointer"
            onClick={(e) => toggleNavbar()}
          />
        </div>
      </div>
      <div className="h-full px-3 py-4 overflow-y-auto">
        {!Admin && (
          <ul className="space-y-2 font-medium">
            <li>
              <NavLink
                to={RouteNavigation.homepage}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "flex items-center p-2 rounded-lg bg-gray-100 text-black group"
                    : "flex items-center p-2"
                }
                onClick={handleNavbarClick}
              >
                <MdDashboard />
                <span className="ms-3">Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={RouteNavigation.departments}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "flex items-center p-2 rounded-lg bg-gray-100 text-black group"
                    : "flex items-center p-2"
                }
                onClick={handleNavbarClick}
              >
                <FaUser />
                <span className="ms-3">Department</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={RouteNavigation.counters}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "flex items-center p-2 rounded-lg bg-gray-100 text-black group"
                    : "flex items-center p-2"
                }
                onClick={handleNavbarClick}
              >
                <IoPieChart />
                <span className="ms-3">Counter</span>
              </NavLink>
            </li>
           
            <li>
              <NavLink
                to={RouteNavigation.users}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "flex items-center p-2 rounded-lg bg-gray-100 text-black group"
                    : "flex items-center p-2"
                }
                onClick={handleNavbarClick}
              >
                <FaUser />
                <span className="ms-3">User</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={RouteNavigation.advertisements}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "flex items-center p-2 rounded-lg bg-gray-100 text-black group"
                    : "flex items-center p-2"
                }
                onClick={handleNavbarClick}
              >
                <FcAdvertising />
                <span className="ms-3">Advertisement</span>
              </NavLink>
            </li>

            <li>
      <div className="relative">
        
        <button
          className={`flex items-center p-2 rounded-lg group w-full transition-colors duration-300 ${
            isAccordionOpen ? 'bg-white text-black' : 'bg-transparent text-white'
          }`}
          onClick={toggleAccordion}
        >
          <TbReport />
          <span className="ms-3">Report</span>
          <span className={`ml-1 transition-transform ${isAccordionOpen ? 'rotate-180' : ''}`}>
          <IoMdArrowDropdown />
          </span>
        </button>

      
        {isAccordionOpen && (
          <div className="mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 w-full">
            <div className="py-1" role="menu">
              <NavLink
                to="/token-detail-report"
                className="flex items-center p-2 text-black hover:bg-gray-100"
                onClick={toggleAccordion}
              >
                Detail Report
              </NavLink>
              <NavLink
                to="/token-summary"
                className="flex items-center p-2 text-black hover:bg-gray-100"
                onClick={toggleAccordion}
              >
                Summary Report
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </li>

            <li>
              <NavLink
                to={RouteNavigation.settings}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "flex items-center p-2 rounded-lg bg-gray-100 text-black group"
                    : "flex items-center p-2"
                }
                onClick={handleNavbarClick}
              >
                <IoSettings />
                <span className="ms-3">Settings</span>
              </NavLink>
            </li>
          </ul>
        )}
        {Admin && (
          <ul className="space-y-2 font-medium">
            <li>
              <NavLink
                to={RouteNavigation.companies}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "flex items-center p-2 rounded-lg bg-gray-100 text-black group"
                    : "flex items-center p-2"
                }
                onClick={handleNavbarClick}
              >
                <FaRegBuilding />
                <span className="ms-3">Company</span>
              </NavLink>
            </li>
          </ul>
        )}
      </div>
    </aside>
  );
}

export default Navbar;
